import React from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { formatNumber } from '@tillersystems/stardust';

import { mealUrl, productUrl } from '../../helpers';
import { Meal, Product } from '../../types';
import { CroppedContent } from '../CroppedContent';
import {
  StyledTabTitle,
  StyledTabContent,
  StyledImageContainer,
  StyledLink,
  StyledProductPrice,
  StyledTabContentText,
} from './style';
import { isMealItem } from './helpers';

export interface PanelContentProps {
  title: string;
  items: (Product | Meal)[];
  baseUrl?: string;
  showSign?: boolean;
}

export const PanelContent: React.FC<PanelContentProps> = ({
  title,
  items,
  baseUrl = '',
  showSign = false,
}) => {
  return (
    <>
      <StyledTabTitle>{title}</StyledTabTitle>
      {items.map(item => {
        const { id, name, price } = item;
        const link = isMealItem(item) ? mealUrl(baseUrl, id) : productUrl(baseUrl, id);
        const image = !isMealItem(item) ? item.image : undefined;

        return (
          <StyledLink to={link} key={`panel_content_${id}`} data-testid="item-link">
            <StyledTabContent>
              <StyledTabContentText>
                {price > 0 && (
                  <StyledProductPrice>
                    {showSign && '+ '}
                    {formatNumber({ number: price, currency: 'eur' })}
                  </StyledProductPrice>
                )}
                <CroppedContent content={name} limit={30} />
              </StyledTabContentText>
              {image?.small ? (
                <StyledImageContainer backgroundImage={image.small} />
              ) : (
                <ArrowForwardIcon data-testid="arrow-forward-icon" />
              )}
            </StyledTabContent>
          </StyledLink>
        );
      })}
    </>
  );
};
