import { Store } from '../types/store';

const apiBaseUrl = process.env.REACT_APP_API_ENDPOINT || '';

export const getStore = async (storeId: number): Promise<Store> =>
  (
    await fetch(`${apiBaseUrl}/${storeId}/menus`, {
      headers: { 'Content-Type': 'application/json', 'X-Tiller-Internal': 'true' },
    })
  ).json();
