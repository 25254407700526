import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';

import { currentBaseUrl, currentStoreState } from '../../state';
import { BackLink, CenteredContent, Logo, MenuNameWithInfo, Tabs, Seo } from '../../components';
import { StyledLogoContainer } from './style';

const Catalog: React.FC = () => {
  const history = useHistory();
  const { name, logo, meals, categories } = useRecoilValue(currentStoreState);
  const baseUrl = useRecoilValue(currentBaseUrl);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <CenteredContent>
      <Seo storeName={name} faviconUrl={logo?.thumbnail} />
      <BackLink onClick={() => history.goBack()} />
      <StyledLogoContainer>
        <Logo
          clientLogo={logo?.thumbnail}
          clientName={<MenuNameWithInfo name={name} onClick={() => setIsModalOpen(!isModalOpen)} />}
        />
      </StyledLogoContainer>
      <Tabs categories={categories} meals={meals} baseUrl={baseUrl} />
    </CenteredContent>
  );
};

export default Catalog;
