import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { ListItemText } from '@material-ui/core/';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { menuUrl } from '../../helpers';
import { Menu } from '../../types';
import { StyledArrowContainer, StyledListItem, StyledList } from './style';

export interface ListProps {
  items: Menu[] | string;
  baseUrl?: string;
}

export const List: React.FC<ListProps> = ({ items, baseUrl = '' }) => {
  const themeContext = useContext(ThemeContext);

  const icon = (
    <ArrowForwardIcon fontSize="large" style={{ color: themeContext.palette.primary.default }} />
  );

  return (
    <StyledList>
      {Array.isArray(items) ? (
        [...items]
          .sort(
            ({ position: previousPosition }, { position: currentPosition }) =>
              previousPosition - currentPosition,
          )
          .map(({ id, name }) => (
            <Link to={menuUrl(baseUrl, id)} key={`listitem_${name}`} data-testid="menu-link">
              <StyledListItem button key={`item_${name}`}>
                <ListItemText primary={name} />
                <StyledArrowContainer>{icon}</StyledArrowContainer>
              </StyledListItem>
            </Link>
          ))
      ) : (
        <Link to={`${baseUrl}/catalog`} data-testid="menu-link">
          <StyledListItem button>
            <ListItemText primary={items} />
            <StyledArrowContainer>{icon}</StyledArrowContainer>
          </StyledListItem>
        </Link>
      )}
    </StyledList>
  );
};
