import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { formatNumber } from '@tillersystems/stardust';

import { BackLink, PanelContent, Seo } from '../../components';
import { currentBaseUrl, currentMealState, currentStoreState } from '../../state';

import { StyledMealContainer, StyledMealTitle } from './styles';

const Meal: React.FC = () => {
  const history = useHistory();
  const { name, price, courseMealChoices, description } = useRecoilValue(currentMealState);
  const { name: storeName, logo } = useRecoilValue(currentStoreState);
  const baseUrl = useRecoilValue(currentBaseUrl);

  return (
    <StyledMealContainer>
      <Seo storeName={storeName} faviconUrl={logo?.thumbnail} />
      <BackLink onClick={() => history.goBack()} />
      <StyledMealTitle>
        {name} {formatNumber({ number: price, currency: 'eur' })}
        {description && <p>{description}</p>}
      </StyledMealTitle>
      {courseMealChoices.map(
        ({ name, products }, index) =>
          products.length > 0 && (
            <PanelContent
              title={name}
              items={products.map(({ extraPrice, ...product }) => ({
                ...product,
                price: extraPrice || 0,
              }))}
              baseUrl={baseUrl}
              showSign={true}
              key={`options_${name}_${index}`}
            />
          ),
      )}{' '}
    </StyledMealContainer>
  );
};

export default Meal;
