import React from 'react';
import { CenteredContent } from '../CenteredContent';
import { StyledLoader, StyledLoaderContainer } from './style';

export const Loader: React.FC = () => (
  <CenteredContent isHomePage>
    <StyledLoaderContainer>
      <StyledLoader color="white" data-testid="loader" />
    </StyledLoaderContainer>
  </CenteredContent>
);
