import React from 'react';
import { useTheme } from 'styled-components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { BackLinkContainer } from './styles';

export interface BackLinkProps {
  onClick: () => void;
}

export const BackLink: React.FC<BackLinkProps> = ({ onClick }) => {
  const themeContext = useTheme();

  return (
    <BackLinkContainer>
      <ArrowBackIcon
        fontSize="large"
        style={{ color: themeContext.palette.primary.default }}
        onClick={onClick}
        data-testid="back-button"
      />
    </BackLinkContainer>
  );
};
