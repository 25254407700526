import styled from 'styled-components';

export const StyledCenteredContent = styled.div<{ isHomePage?: boolean; }>`
    align-items: center;
    box-sizing: border-box;
    color: ${({ theme: { palette } }) => palette.primary.default};
    display: flex;
    flex-direction: column;
    font-size: ${({ theme: { font } }) => font.size.default};
    min-height: 100vh;
    line-height: ${({ theme: { font } }) => font.size.xlarge};
    padding-top: ${(props) => props.theme.dimensions.headerPaddingTopOther};
    text-align: center;
    width: 100%;
    background-color: ${({ theme: { palette } }) => palette.common.white};

    ${(props) => props.isHomePage && `padding-top: ${props.theme.dimensions.headerPaddingTopHome};`}
`;
