import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { formatNumber } from '@tillersystems/stardust';
import { BackLink, CroppedContent, Seo } from '../../components';
import { currentProductState, currentStoreState } from '../../state';
import {
  StyledProductContainer,
  StyledProductTitle,
  StyledProductContent,
  StyledOptionsTitle,
  StyledGrid,
  StyledTabContent,
  StyledImageTopContainer,
  StyledImageContainer,
} from './styles';

export const Product: React.FC = () => {
  const history = useHistory();
  const { id, name, image, description, options = [] } = useRecoilValue(currentProductState);
  const { logo, name: storeName } = useRecoilValue(currentStoreState);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [id]);

  return (
    <StyledProductContainer>
      <Seo storeName={storeName} faviconUrl={logo?.thumbnail} />
      <BackLink onClick={() => history.goBack()} />
      {image?.large && <StyledImageTopContainer backgroundImage={image.large} />}
      <StyledProductTitle>{name}</StyledProductTitle>
      {description && <StyledProductContent>{description}</StyledProductContent>}
      {options.map(
        ({ category, products }, index) =>
          products.length > 0 && (
            <React.Fragment key={`options_${category}_${index}`}>
              <StyledOptionsTitle>{category}</StyledOptionsTitle>
              {[...products]
                .sort(
                  ({ position: previousPosition }, { position: currentPosition }) =>
                    previousPosition - currentPosition,
                )
                .map(({ name, price, image }, index) => (
                  <StyledTabContent
                    alignItems="center"
                    container
                    key={`option_${category}_${index}`}
                    data-testid={`supp-item-${index}`}
                  >
                    {price > 0 && (
                      <StyledGrid item xs={2} sm={1}>
                        + {formatNumber({ number: price, currency: 'eur' })}
                      </StyledGrid>
                    )}
                    <StyledGrid item xs={price > 0 ? 10 : 12}>
                      {image?.small && <StyledImageContainer backgroundImage={image.small} />}
                      <CroppedContent content={name} limit={image?.small ? 50 : 70} />
                    </StyledGrid>
                  </StyledTabContent>
                ))}
            </React.Fragment>
          ),
      )}
    </StyledProductContainer>
  );
};
