import React from 'react';
import { StyledLogoContainer, StyledContent, StyledClientLogo } from './style';

export interface LogoProps {
  clientName?: React.ReactNode;
  clientLogo?: string;
}

export const Logo: React.FC<LogoProps> = ({ clientName, clientLogo }) => (
  <StyledLogoContainer>
    {clientLogo && <StyledClientLogo src={clientLogo} data-testid="client-logo" />}
    <StyledContent data-testid="client-name">{clientName}</StyledContent>
  </StyledLogoContainer>
);
