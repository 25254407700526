import styled from 'styled-components';

export const HomepageLogoContainer = styled.div`
    margin-bottom: 10.9rem;
`;

export const StyledBackButton = styled.a`
    text-decoration: none;
    cursor: pointer;
    color: ${({ theme: { palette } }) => palette.secondary.default};
`;
