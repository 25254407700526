import styled from 'styled-components';

export const StyledMealContainer = styled.div`
  font-size: 1.4rem;
  padding-top: 4.8rem;
  text-align: center;
`;

export const StyledMealTitle = styled.div`
  margin-bottom: 1.2rem;
  text-transform: uppercase;
`;
