import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { useRecoilState } from 'recoil';

import { ErrorMessage, Loader } from '../../components';
import { Meal } from '../../pages';
import { currentMealIdState } from '../../state';
import { ProductRoutes } from '../ProductRoutes';

interface MealRoutesParams {
  storeId?: string;
  storeName?: string;
  mealId?: string;
}

const MealRoutes: React.FC = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { mealId: mealIdParam, storeId, storeName } = useParams<MealRoutesParams>();
  const mealId = Number(mealIdParam);
  const [currentMealId, setMealId] = useRecoilState(currentMealIdState);

  useEffect(() => {
    if (Number.isInteger(mealId) && mealId !== currentMealId) {
      setMealId(mealId);
    }
  }, [currentMealId, mealId, setMealId]);

  if (!currentMealId) {
    return <Loader />;
  }

  return (
    <ErrorBoundary
      fallback={
        <ErrorMessage
          url={`/${storeId}/${storeName}`}
          message={t('digitalmenuapp.error.message.meal')}
        />
      }
    >
      <Switch>
        <Route path={`${path}/product/:productId`}>
          <ProductRoutes />
        </Route>
        <Route>
          <Meal />
        </Route>
      </Switch>
    </ErrorBoundary>
  );
};

export default MealRoutes;
