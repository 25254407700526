import styled from 'styled-components';
import { List, ListItem, ListItemIcon } from '@material-ui/core/';

export const StyledListItem = styled(ListItem)`
    background-color: ${({ theme: { palette } }) => palette.background.paper} !important;
    text-transform: uppercase;

    span {
        font-size: ${({ theme: { font } }) => font.size.medium};
        color: ${({ theme: { palette } }) => palette.primary.default};
    }
`;

export const StyledArrowContainer = styled(ListItemIcon)`
    min-width: auto !important;
`;

export const StyledList = styled(List)`
    width: 100%;

    a {
        text-decoration: none;
    }
`;
