import { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { CenteredContent, Loader } from './components';
import i18n from './i18n';
import { AppRoutes } from './routes/AppRoutes';
import { GlobalStyle, themeLight } from './styles';
import GoogleAnalyticsTracker from './components/GoogleAnalyticsTracker/index';
import { GoogleTagManagerScript } from './components/GoogleTagManagerScript/gtm';

function App() {
  return (
    <ThemeProvider theme={themeLight}>
      <GlobalStyle />
      <GoogleTagManagerScript />
      <I18nextProvider i18n={i18n}>
        <Suspense
          fallback={
            <CenteredContent isHomePage>
              <Loader data-testid="loader" />
            </CenteredContent>
          }
        >
          <RecoilRoot>
            <Router>
              <Route component={GoogleAnalyticsTracker} />
              <AppRoutes />
            </Router>
          </RecoilRoot>
        </Suspense>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
