import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import { currentBaseUrl, currentMenuState, currentStoreState } from '../../state';
import {
  BackLink,
  CenteredContent,
  Logo,
  MenuNameWithInfo,
  Modal,
  Tabs,
  Seo,
} from '../../components';
import ModalContent from './ModalContent';
import { StyledLogoContainer } from './style';

export const Menu: React.FC = () => {
  const history = useHistory();
  const { name, categories, meals, periods = [] } = useRecoilValue(currentMenuState);
  const { logo: storeLogo, name: storeName } = useRecoilValue(currentStoreState);
  const baseUrl = useRecoilValue(currentBaseUrl);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const hasOpeningHours = periods[0]?.days.length > 0;

  return (
    <CenteredContent>
      <Seo storeName={storeName} faviconUrl={storeLogo?.thumbnail} />
      <BackLink onClick={() => history.goBack()} />
      <StyledLogoContainer>
        <Logo
          clientLogo={storeLogo?.thumbnail}
          clientName={
            <MenuNameWithInfo
              hasOpeningHours={hasOpeningHours}
              name={name}
              onClick={() => setIsModalOpen(!isModalOpen)}
            />
          }
        />
      </StyledLogoContainer>
      <Tabs categories={categories} meals={meals} baseUrl={baseUrl} />
      {hasOpeningHours && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} data-testid="modal">
          <ModalContent periods={periods} />
        </Modal>
      )}
    </CenteredContent>
  );
};
