import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useRecoilState } from 'recoil';

import { ErrorMessage, Loader } from '../../components';
import { Menu } from '../../pages/Menu';
import { currentMenuIdState } from '../../state';

interface MenuRoutesParams {
  storeId?: string;
  storeName?: string;
  menuId?: string;
}

const MenuRoutes: React.FC = () => {
  const { t } = useTranslation();
  const { menuId: menuIdParam, storeId, storeName } = useParams<MenuRoutesParams>();
  const menuId = Number(menuIdParam);
  const [currentMenuId, setMenuId] = useRecoilState(currentMenuIdState);

  useEffect(() => {
    if (Number.isInteger(menuId) && menuId !== currentMenuId) {
      setMenuId(menuId);
    }
  }, [currentMenuId, menuId, setMenuId]);

  if (!currentMenuId) {
    return <Loader />;
  }

  return (
    <ErrorBoundary
      fallback={
        <ErrorMessage
          url={`/${storeId}/${storeName}`}
          message={t('digitalmenuapp.error.message.menu')}
        />
      }
    >
      <Menu />
    </ErrorBoundary>
  );
};

export default MenuRoutes;
