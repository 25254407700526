import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledTabTitle = styled.div`
    background-color: ${({ theme: { palette } }) => palette.tabTitle.backgroundColor};
    color: ${({ theme: { palette } }) => palette.tabTitle.color};
    font-size: ${({ theme: { font } }) => font.size.medium};
    padding: ${({ theme: { spacing } }) => spacing.default};
    text-align: left;
    text-transform: uppercase;
`;

export const StyledTabContent = styled.div<{ isClickable?: boolean; }>`
    align-items: center;
    background: ${({ theme: { palette } }) => palette.tabContent.backgroundColor};
    box-sizing: border-box;
    color: ${({ theme: { palette } }) => palette.tabContent.color};
    cursor: pointer;
    display: flex;
    font-size: ${({ theme: { font } }) => font.size.medium};
    justify-content: space-between;
    padding: ${({ theme: { spacing } }) => `${spacing.default} ${spacing.xxxlarge}`};
    text-align: left;
    width: 100%;
`;

export const StyledImageContainer = styled.div<{ backgroundImage: string; }>`
    background-image: url('${(props) => props.backgroundImage}');
    background-size: cover;
    background-position: center center;
    min-width: ${({ theme: { dimensions } }) => dimensions.productPictureMaxWidth};
    height: ${({ theme: { dimensions } }) => dimensions.productPictureHeight};
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
`;

export const StyledTabContentText = styled.div`
    display: flex;
    padding-right: 1rem;
`;

export const StyledProductPrice = styled.span`
    display: inline-block;
    width: 5rem;
`;
