import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Category, CategoryType, Meal } from '../../types';
import { TabPanel } from '../TabPanel';
import { CroppedContent } from '../CroppedContent';
import { PanelContent } from '../PanelContent';
import { StyledTab, StyledTabs } from './style';

export interface TabsProps {
  baseUrl?: string;
  meals?: Meal[];
  categories?: Category[];
}

export const Tabs: React.FC<TabsProps> = ({ baseUrl = '', meals = [], categories = [] }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const productCategories = categories.filter(
    ({ type, products }) => type === CategoryType.Product && products.length > 0,
  );
  const showAll = meals.length + productCategories.length > 1;
  const showMeals = meals.length > 0;
  const showCategoriesTab =
    productCategories.length > 1 || (productCategories.length > 0 && showMeals);

  return (
    <>
      <StyledTabs
        value={selectedTab}
        textColor="primary"
        onChange={(_, tabIndex) => {
          setSelectedTab(tabIndex);
        }}
        variant="scrollable"
        scrollButtons="off"
        data-testid="tab-button"
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        {showAll && (
          <StyledTab
            label={t('digitalmenuapp.all.category')}
            key="tab_all"
            id="simple-tab-all"
            aria-controls="simple-tabpanel-all"
            data-testid="category-title-all"
          />
        )}
        {showMeals && (
          <StyledTab
            label={t('digitalmenuapp.all.meals')}
            key="tab_meals"
            id="simple-tab-meals"
            aria-controls="simple-tabpanel-meals"
            data-testid="category-title-meals"
          />
        )}
        {showCategoriesTab &&
          [...productCategories]
            .sort(
              ({ position: previousPosition }, { position: currentPosition }) =>
                previousPosition - currentPosition,
            )
            .map(({ name }, index) => (
              <StyledTab
                label={<CroppedContent content={name} limit={25} />}
                key={`tab_category_${index}`}
                id={`simple-tab-category-${index}`}
                aria-controls={`simple-tabpanel-category-${index}`}
                data-testid={`category-title-${name}`}
              />
            ))}
      </StyledTabs>
      {showAll && (
        <TabPanel
          value={selectedTab}
          index={0}
          key="simple-tab-all"
          data-testid={`category-content-${selectedTab}`}
        >
          {showMeals && (
            <PanelContent
              title={t('digitalmenuapp.all.meals')}
              key={`panel-meals`}
              items={meals}
              baseUrl={baseUrl}
            />
          )}
          {[...productCategories]
            .sort(
              ({ position: previousPosition }, { position: currentPosition }) =>
                previousPosition - currentPosition,
            )
            .map(({ id, name, products }, index) => (
              <PanelContent
                title={name}
                key={`panel-${id}-${index}`}
                items={products}
                baseUrl={baseUrl}
              />
            ))}
        </TabPanel>
      )}
      {showMeals && (
        <TabPanel
          value={selectedTab}
          index={showAll ? 1 : 0}
          key="simple-tab-meals"
          data-testid={`category-content-${selectedTab}`}
        >
          <PanelContent title={t('digitalmenuapp.all.meals')} items={meals} baseUrl={baseUrl} />
        </TabPanel>
      )}
      {[...productCategories]
        .sort(
          ({ position: previousPosition }, { position: currentPosition }) =>
            previousPosition - currentPosition,
        )
        .map(({ name, products }, index) => (
          <TabPanel
            value={selectedTab}
            index={index + (showAll ? 1 : 0) + (showMeals ? 1 : 0)}
            key={`simple-tab-category-${index}`}
            data-testid={`category-content-${selectedTab}`}
          >
            <PanelContent title={name} items={products} baseUrl={baseUrl} />
          </TabPanel>
        ))}
    </>
  );
};
