import { Switch, Route } from 'react-router-dom';
import { StoreRoutes } from '../StoreRoutes';

import { NotFound } from '../../pages';

function AppRoutes() {
  return (
    <Switch>
      <Route path="/:storeId/:storeName">
        <StoreRoutes />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}

export default AppRoutes;
