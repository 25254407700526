import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const StyledProductContainer = styled.div`
    font-size: 1.4rem;
    padding-top: 4.8rem;
    text-align: center;
`;

export const StyledProductTitle = styled.div`
    margin-bottom: 1.2rem;
    text-transform: uppercase;
`;

export const StyledProductContent = styled.div`
    margin-bottom: 3.2rem;
`;

export const StyledOptionsTitle = styled.div`
    background-color: ${({ theme: { palette } }) => palette.tabTitle.backgroundColor};
    color: ${({ theme: { palette } }) => palette.tabTitle.color};
    padding: 1.6rem;
    text-align: left;
    text-transform: uppercase;
`;

export const StyledTabContent = styled(Grid)`
    background: ${({ theme: { palette } }) => palette.tabContent.backgroundColor};
    padding: ${({ theme: { spacing } }) => `${spacing.default} ${spacing.xxxlarge}`};
    font-size: ${({ theme: { font } }) => font.size.medium};
`;

export const StyledGrid = styled(Grid)`
    text-align: left;
`;

export const StyledImageContainer = styled.div<{ backgroundImage: string; }>`
    background-image: url('${(props) => props.backgroundImage}');
    background-size: cover;
    background-position: center center;
    float: right;
    height: ${({ theme: { dimensions } }) => dimensions.productPictureHeight};
    max-width: ${({ theme: { dimensions } }) => dimensions.productPictureMaxWidth};
    width: 100%;
`;

export const StyledImageTopContainer = styled(StyledImageContainer)`
    height: ${({ theme: { dimensions } }) => dimensions.productPictureHeightLarge};
    margin-bottom: 3.2rem;
    max-width: 100%;
`;
