import styled from 'styled-components';

export const StyledModalBody = styled.div`
  background-color: ${({ theme: { palette } }) => palette.common.white};
  color: ${({ theme: { palette } }) => palette.common.black};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 4.2rem ${({ theme: { font } }) => font.size.default}
    ${({ theme: { font } }) => font.size.default};
  font-size: ${({ theme: { font } }) => font.size.medium};
  width: ${({ theme: { dimensions } }) => dimensions.modalWidth};
  height: ${({ theme: { dimensions } }) => dimensions.modalHeight};
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 0.5rem;
  outline: none;

  button {
    background-color: ${({ theme: { palette } }) => palette.greyShade.mediumGrey};
    color: ${({ theme: { palette } }) => palette.common.white};
    font-size: ${({ theme: { font } }) => font.size.large};
    height: 6rem;
    width: 100%;
    text-transform: capitalize;
  }
`;

export const StyledModalContent = styled.div`
  height: 25rem;
  padding: 0 3.6rem;
  margin: 0 auto 1rem;
  overflow-y: scroll;

  p {
    line-height: ${({ theme: { font } }) => font.size.xlarge};
    margin: 0 0 1.2rem;
  }
`;

export const StyledCloseIconContainer = styled.div`
  cursor: pointer;
  position: fixed;
  top: 1.8rem;
  left: 1.8rem;
`;
