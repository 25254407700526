import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const StyledTabs = styled(Tabs)`
    border-top: 1px solid ${({ theme: { palette } }) => palette.common.white};
    width: 100%;
`;

export const StyledTab = styled(Tab)`
    width: 38%;
    max-width: inherit!important;
    box-sizing: border-box;
    height: ${({ theme: { dimensions } }) => dimensions.tabHeight};

    &:nth-child(odd) {
        background-color: ${({ theme: { palette } }) => palette.tab.odd.backgroundColor};
        color: ${({ theme: { palette } }) => palette.tab.odd.color};
    }
    &:nth-child(even) {
        background-color: ${({ theme: { palette } }) => palette.tab.even.backgroundColor};
        color: ${({ theme: { palette } }) => palette.tab.even.color};
    }

    span {
        font-size: ${({ theme: { font } }) => font.size.medium};
        line-height: ${({ theme: { font } }) => font.size.large};
    }

    &.Mui-selected {
        background-color: ${({ theme: { palette } }) => palette.tab.current.backgroundColor};
        color: ${({ theme: { palette } }) => palette.tab.current.color} !important;
    }
`;
