export enum CategoryType {
  Option = 'OPTION',
  Product = 'PRODUCT',
}

export interface Store {
  id: number;
  name: string;
  logo?: Picture;
  menus?: Menu[];
  categories?: Category[];
  meals?: Meal[];
}

export interface Picture {
  large: string;
  small: string;
  medium: string;
  thumbnail: string;
}

export interface Menu {
  id: number;
  name: string;
  position: number;
  categories: Category[];
  meals: Meal[];
  periods?: OpeningHours[];
}

export interface Category {
  id: number;
  name: string;
  color: string;
  position: number;
  type: CategoryType;
  products: Product[];
}

export interface Meal {
  id: number;
  name: string;
  position: number;
  price: number;
  description?: string;
  courseMealChoices: CourseMealChoice[];
}

export interface CourseMealChoice {
  id: number;
  name: string;
  position: number;
  products: Product[];
}

export interface Product {
  id: number;
  name: string;
  description?: string;
  price: number;
  position: number;
  image?: Picture;
  options?: Option[];
  extraPrice?: number;
}

export interface Option {
  category: string;
  products: OptionProduct[];
}

export interface OptionProduct {
  id: number;
  name: string;
  description: string;
  price: number;
  position: number;
  image?: Picture;
}

export interface OpeningHours {
  days: string[];
  start: string;
  end: string;
}
