import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { useRecoilState } from 'recoil';

import { ErrorMessage, Loader } from '../../components';
import { currentStoreIdState } from '../../state';
import { Home, Catalog } from '../../pages/';
import { MenuRoutes, MealRoutes, ProductRoutes } from '..';

function StoreRoutes() {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { storeId: storeIdParam } = useParams<{ storeId?: string }>();
  const storeId = Number(storeIdParam);
  const [currentStoreId, setStoreId] = useRecoilState(currentStoreIdState);

  useEffect(() => {
    if (Number.isInteger(storeId) && storeId !== currentStoreId) {
      setStoreId(storeId);
    }
  }, [currentStoreId, setStoreId, storeId]);

  if (!currentStoreId) {
    return <Loader />;
  }

  return (
    <ErrorBoundary fallback={<ErrorMessage message={t('digitalmenuapp.error.message.store')} />}>
      <Switch>
        <Route path={`${path}/menu/:menuId`}>
          <MenuRoutes />
        </Route>
        <Route path={`${path}/meal/:mealId`}>
          <MealRoutes />
        </Route>
        <Route path={`${path}/product/:productId`}>
          <ProductRoutes />
        </Route>
        <Route path={`${path}/catalog`}>
          <Catalog />
        </Route>
        <Route>
          <Home />
        </Route>
      </Switch>
    </ErrorBoundary>
  );
}

export default StoreRoutes;
