import styled from 'styled-components';

export const StyledLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;

export const StyledClientLogo = styled.img`
  margin-bottom: 3.2rem;
  max-height: ${({ theme: { dimensions } }) => dimensions.logoMaxHeight};
`;

export const StyledContent = styled.h1`
  color: ${({ theme: { palette } }) => palette.primary.default};
  font-size: ${({ theme: { typography } }) => typography.h1.fontSize};
  font-weight: ${({ theme: { typography } }) => typography.h1.fontWeight};
  margin: 0;
  text-align: center;
  text-transform: uppercase;
`;
