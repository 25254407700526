import { kebabCase } from 'lodash';
import { atom, errorSelector, selector, selectorFamily } from 'recoil';

import { getStore } from '../services';
import { findProductInCategories, findProductInMeals } from './helpers';

export const currentStoreIdState = atom<number | null>({
  key: 'currentStoreIdState',
  default: null,
});

export const storeQuery = selectorFamily({
  key: 'storeQuery',
  get: (storeId: number) => async () => getStore(storeId),
});

export const currentStoreState = selector({
  key: 'currentStoreQuery',
  get: ({ get }) => {
    const currentStoreId = get(currentStoreIdState);
    if (!currentStoreId) {
      return errorSelector('Store id cannot be a nullable value');
    }
    return get(storeQuery(currentStoreId));
  },
});

/**
 * Menu
 */
export const currentMenuIdState = atom<number | null>({
  key: 'currentMenuIdState',
  default: null,
});

export const menuState = selectorFamily({
  key: 'menuState',
  get:
    (menuId: number) =>
    ({ get }) => {
      const menu = get(currentStoreState)?.menus?.find(({ id }) => id === menuId);
      if (!menu) {
        return errorSelector(`Cannot find menu with menu id ${menuId}`);
      }
      return menu;
    },
});

export const currentMenuState = selector({
  key: 'currentMenuState',
  get: ({ get }) => {
    const currentMenuId = get(currentMenuIdState);
    if (!currentMenuId) {
      return errorSelector('Menu id cannot be a nullable value');
    }
    return get(menuState(currentMenuId));
  },
});

/**
 * Meal
 */
export const currentMealIdState = atom<number | null>({
  key: 'currentMealIdState',
  default: null,
});

const mealState = selectorFamily({
  key: 'mealState',
  get:
    (mealId: number) =>
    ({ get }) => {
      const { meals, menus = [] } = get(currentStoreState);
      let meal = meals?.find(({ id }) => id === mealId);
      if (meal) {
        return meal;
      }

      for (const menu of menus) {
        const { meals } = menu;
        const meal = meals.find(({ id }) => id === mealId);
        if (meal) {
          return meal;
        }
      }

      return errorSelector(`Cannot find meal with meal id ${mealId}`);
    },
});

export const currentMealState = selector({
  key: 'currentMealState',
  get: ({ get }) => {
    const currentMealId = get(currentMealIdState);
    if (!currentMealId) {
      return errorSelector('Meal id cannot be a nullable value');
    }

    return get(mealState(currentMealId));
  },
});

/**
 * Product
 */
export const currentProductIdState = atom<number | null>({
  key: 'currentProductIdState',
  default: null,
});

const productState = selectorFamily({
  key: 'productState',
  get:
    (productId: number) =>
    ({ get }) => {
      const {
        categories: storeCategories = [],
        meals: storeMeals = [],
        menus = [],
      } = get(currentStoreState);

      let product =
        findProductInMeals(productId, storeMeals) ||
        findProductInCategories(productId, storeCategories);
      if (product) {
        return product;
      }

      for (const menu of menus) {
        const { categories, meals } = menu;
        product =
          findProductInMeals(productId, meals) || findProductInCategories(productId, categories);
        if (product) {
          return product;
        }
      }

      return errorSelector(`Cannot find product with product id ${productId}`);
    },
});

export const currentProductState = selector({
  key: 'currentProductState',
  get: ({ get }) => {
    const currentProductId = get(currentProductIdState);
    if (!currentProductId) {
      return errorSelector('Product id cannot be a nullable value');
    }

    return get(productState(currentProductId));
  },
});

/**
 * Urls
 */
export const currentBaseUrl = selector({
  key: 'currentBaseUrl',
  get: ({ get }) => {
    const { id, name } = get(currentStoreState);

    return `/${id}/${kebabCase(name)}`;
  },
});
