import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body, html {
    background-color: ${(props) => props.theme.palette.common.white};
    margin: 0;
    padding: 0;
    font-size: 10px;
  }

  body {
    color: ${(props) => props.theme.palette.primary.default};
    font-family: sans-serif;

    a {
      color: ${(props) => props.theme.palette.primary.default};
    }
  }
`;
