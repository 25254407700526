import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translateCa from '../locales/ca/translations.json';
import translateDe from '../locales/de/translations.json';
import translateEn from '../locales/en/translations.json';
import translateEnGB from '../locales/en-GB/translations.json';
import translateEs from '../locales/es/translations.json';
import translateFr from '../locales/fr/translations.json';
import translateIt from '../locales/it/translations.json';
import translatePt from '../locales/pt/translations.json';
import { getFallbackLanguagesCodes } from '@tiller/translations';

const languages = [
  { code: 'ca', name: 'Catalan' },
  { code: 'de', name: 'German' },
  { code: 'en', name: 'English' },
  { code: 'en-GB', name: 'English(UK)' },
  { code: 'es', name: 'Spanish' },
  { code: 'fr', name: 'French' },
  { code: 'it', name: 'Italian' },
  { code: 'pt', name: 'Portugese' },
];

const availableLanguages = languages.map(lng => lng.code);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: code => getFallbackLanguagesCodes({ languageCode: code, availableLanguages }),
    load: 'currentOnly', // to handle en-GB : https://github.com/i18next/i18next/issues/1506#issuecomment-694683792

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    resources: {
      ca: {
        translations: translateCa,
      },
      de: {
        translations: translateDe,
      },
      en: {
        translations: translateEn,
      },
      'en-GB': {
        translations: translateEnGB,
      },
      es: {
        translations: translateEs,
      },
      fr: {
        translations: translateFr,
      },
      it: {
        translations: translateIt,
      },
      pt: {
        translations: translatePt,
      },
    },

    debug: process.env.NODE_ENV === 'development',

    keySeparator: '.',

    interpolation: {
      escapeValue: false,
    },

    react: {
      useSuspense: true,
    },
  });

const getPrefix = (n: number) => Number(n > 1 ? 2 : n);

languages.forEach(({ code, name }) => {
  i18n.services.pluralResolver.addRule(code, {
    name,
    numbers: [0, 1, 2],
    plurals: getPrefix,
  });
});

export default i18n;
