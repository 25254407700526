import { DefaultTheme } from 'styled-components';

const themeLight: DefaultTheme = {
  dimensions: {
    headerPaddingTopHome: '9.8rem',
    headerPaddingTopOther: '2.4rem',
    logoMaxHeight: '11.2rem',
    modalHeight: '32rem',
    modalWidth: '29rem',
    productPictureHeight: '8.2rem',
    productPictureHeightLarge: '19.8rem',
    productPictureMaxWidth: '12rem',
    tabHeight: '7rem',
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 414,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  font: {
    size: {
      small: '1rem',
      medium: '1.4rem',
      default: '1.6rem',
      large: '1.8rem',
      xlarge: '2rem',
      xxlarge: '2.2rem',
      xxxlarge: '2.4rem',
    },
  },
  spacing: {
    small: '1rem',
    medium: '1.4rem',
    default: '1.6rem',
    large: '1.8rem',
    xlarge: '2rem',
    xxlarge: '2.2rem',
    xxxlarge: '2.4rem',
  },
  palette: {
    primary: {
      default: '#2B323B',
    },
    secondary: {
      default: '#1F95CE',
    },
    success: {
      default: 'hsl(89,53%,52%)',
    },
    failure: {
      default: 'hsl(6,79%,65%)',
    },
    warning: {
      default: 'hsl(34,91%,65%)',
    },
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
    background: {
      default: '#e5e5e5',
      paper: '#f6f9fd',
    },
    greyShade: {
      mysticGrey: 'hsl(218, 29%, 95%)',
      lightGrey: 'hsl(207,22%,90%)',
      mediumGrey: 'hsl(206,23%,69%)',
      darkGrey: 'hsl(206,16%,60%)',
      spaceGrey: 'hsl(207,13%,45%)',
      paleGrey: 'hsl(240,33%,99%)',
      veryLightGrey: 'hsl(214,64%,98%)',
    },
    list: {
      backgroundColor: '#FFFFFF',
    },
    tab: {
      color: '#FFFFFF',
      current: {
        color: '#FFFFFF',
        backgroundColor: '#1F95CE',
      },
      odd: {
        color: '#000000',
        backgroundColor: '#E1E6EA',
      },
      even: {
        color: '#000000',
        backgroundColor: '#ECEEF3',
      },
    },
    tabTitle: {
      color: '#2B323B',
      backgroundColor: '#F7F9FD',
    },
    tabContent: {
      color: '#2B323B',
      backgroundColor: '#FFFFFF',
    },
  },
  typography: {
    h1: {
      color: '#FFFFFF',
      fontSize: '1.4rem',
      fontWeight: 500,
    },
    body1: {
      color: '#FFFFFF',
      fontSize: '1.4rem',
      fontWeight: 400,
    },
  },
};

export { themeLight };
