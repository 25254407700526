import React from 'react';
import { StyledTabContainer } from './style';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  onClick?: () => void;
}

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  index,
  value,
  onClick,
  ...props
}) => (
  <StyledTabContainer
    role="tabpanel"
    hidden={value !== index}
    id={`scrollable-prevent-tabpanel-${index}`}
    aria-labelledby={`scrollable-prevent-tab-${index}`}
    onClick={onClick}
    {...props}
  >
    {value === index && children}
  </StyledTabContainer>
);
