import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { currentBaseUrl, currentStoreState } from '../../state';
import { CenteredContent, List, Logo, LogoContainer, Seo } from '../../components';

export const Home: React.FC = () => {
  const { t } = useTranslation();
  const { logo, name, menus = [] } = useRecoilValue(currentStoreState);
  const baseUrl = useRecoilValue(currentBaseUrl);

  return (
    <CenteredContent isHomePage>
      <Seo storeName={name} faviconUrl={logo?.thumbnail} />
      <LogoContainer>
        <Logo
          clientName={t('digitalmenuapp.welcome.message', { name })}
          clientLogo={logo?.thumbnail}
        />
      </LogoContainer>
      <List
        items={menus.length > 0 ? menus : t('digitalmenuapp.defaultmenu.name')}
        baseUrl={baseUrl}
      />
    </CenteredContent>
  );
};
