import { useTranslation } from 'react-i18next';

import { CenteredContent } from '../CenteredContent';
import { StyledBackLink } from './styles';

interface ErrorMessageProps {
  url?: string;
  message: string;
}

const ErrorMessage = ({ url, message }: ErrorMessageProps) => {
  const { t } = useTranslation();

  return (
    <CenteredContent>
      <p>{message}</p>
      {url && (
        <StyledBackLink to={url} data-testid="error-back-home">
          {t('digitalmenuapp.error.message.back')}
        </StyledBackLink>
      )}
    </CenteredContent>
  );
};

export default ErrorMessage;
