import React from 'react';
import { Grid } from '@material-ui/core';
import { convertDateToHours } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { OpeningHours } from '../../types';

interface ModalContentProps {
  periods: OpeningHours[];
}

const ModalContent: React.FC<ModalContentProps> = ({ periods }) => {
  const { t } = useTranslation();

  return (
    <Grid container justify="center" alignItems="baseline" data-testid="store-hours">
      <Grid item xs={12}>
        {periods.map(({ days, start, end }, index) => (
          <React.Fragment key={index}>
            {days.map((day, idx) => (
              <Grid container justify="center" alignItems="baseline" key={idx}>
                <Grid item xs={6}>
                  {t(`digitalmenuapp.menu.schedule.${day}`)}
                </Grid>
                <Grid item xs={6}>
                  <p>
                    {convertDateToHours(start)}
                    <br />
                    {convertDateToHours(end)}
                  </p>
                </Grid>
              </Grid>
            ))}
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

export default ModalContent;
