import styled from 'styled-components';

export const StyledInfoIconContainer = styled.span`
    cursor: pointer;
    display: inline-block;
    margin-left: 1rem;
`;

export const StyledMenuNameContainer = styled.div`
    color: ${({ theme: { palette } }) => palette.primary.default};
    display: flex;
    align-items: end;
    justify-content: center;
`;

export const StyledLogoContainer = styled.div`
    margin-bottom: 2.8rem;
`;

export const StyledBackButton = styled.span`
    cursor: pointer;
    color: ${({ theme: { palette } }) => palette.secondary.default};
`;
