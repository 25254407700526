import React from 'react';
import { Icon } from '@tillersystems/stardust';
import { StyledInfoIconContainer, StyledMenuNameContainer } from './styles';
import { useTheme } from 'styled-components';

interface MenuNameWithInfoProps {
  name: string;
  hasOpeningHours?: boolean;
  onClick: () => void;
}

const MenuNameWithInfo: React.FC<MenuNameWithInfoProps> = ({ name, hasOpeningHours, onClick }) => {
  const { palette } = useTheme();

  return (
    <StyledMenuNameContainer>
      {name}
      {hasOpeningHours && (
        <StyledInfoIconContainer>
          <Icon
            name="calendar"
            onClick={onClick}
            color={palette.primary.default}
            size="16"
            title="calendar"
            data-testid="store-hours-button"
          />
        </StyledInfoIconContainer>
      )}
    </StyledMenuNameContainer>
  );
};

export default MenuNameWithInfo;
