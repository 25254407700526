import React from 'react';
import { StyledCenteredContent } from './style';

export interface CenteredContentProps {
  children: React.ReactNode;
  isHomePage?: boolean;
}

export const CenteredContent: React.FC<CenteredContentProps> = ({ children, isHomePage }) => (
  <StyledCenteredContent isHomePage={isHomePage}>{children}</StyledCenteredContent>
);
