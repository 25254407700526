import { Helmet } from "react-helmet";

declare global {
  interface Window {
    dataLayer?: unknown[];
  }
}

const GTM_URL = 'www.googletagmanager.com/gtm.js?id=';

export function GoogleTagManagerScript(): JSX.Element {
  /* eslint-disable jsx-a11y/iframe-has-title */
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
        >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//${GTM_URL}'+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${
          process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID ?? ''
        }');`}</script>
      </Helmet>
       <noscript>
         <iframe
           src={`//www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID ?? ''}`}
           height="0"
           width="0"
           style={{ display: 'none', visibility: 'hidden' }}
         ></iframe>
       </noscript>
     </>
  );
}

// SOURCE:
// <!-- Google Tag Manager -->
// <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
// new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
// j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
// 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
// })(window,document,'script','dataLayer','GTM-P5R3KJM');</script>
// <!-- End Google Tag Manager -->

// <!-- Google Tag Manager (noscript) -->
// <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P5R3KJM"
// height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
// <!-- End Google Tag Manager (noscript) →
