import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CenteredContent, Seo, Logo, LogoContainer } from '../../components';

import { StyledBackButton } from './styles';

export const NotFound: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <CenteredContent isHomePage>
      <Seo />
      <LogoContainer>
        <Logo clientName="SumUp" />
      </LogoContainer>
      <p>{t('digitalmenuapp.error.message.store')}</p>
      <StyledBackButton onClick={() => history.goBack()} data-testid="error-back-home">
        {t('digitalmenuapp.error.message.back')}
      </StyledBackButton>
    </CenteredContent>
  );
};
