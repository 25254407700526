import styled from 'styled-components';
import { Loader } from '@tillersystems/stardust';

export const StyledLoaderContainer = styled.div`
    background: rgba(0, 0, 0, .5);
    outline: none;
    min-height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2000;
`;

export const StyledLoader = styled(Loader)`
    position: absolute;
    top: 50%;
    left: 50%;
`;
