import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

export interface SeoProps {
  faviconUrl?: string;
  storeName?: string;
}

export const Seo: React.FC<SeoProps> = ({ faviconUrl, storeName }) => {
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>
        {storeName
          ? `${storeName} - ${t('digitalmenuapp.nav.name')}`
          : t('digitalmenuapp.nav.name')}
      </title>
      <link
        rel="icon"
        type="image/png"
        href={faviconUrl || `${process.env.PUBLIC_URL}/favicon-light-192.png`}
        sizes="192x192"
      />
    </Helmet>
  );
};
