import { useEffect } from 'react';
import ReactGA from 'react-ga';

// initialize Google Analytics
const debug = process.env.REACT_APP_APP_ENV !== 'production';
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
  testMode: process.env.NODE_ENV === 'test', // use testMode only for test
  debug,
});
if (debug) {
  // Don't send any hit on local & staging environement
  ReactGA.ga('set', 'sendHitTask', null);
}

/**
 * Google Analytics Tracker
 *
 * This component send page view to Google Analytics.
 *
 * @param location - user location
 */
const GoogleAnalyticsTracker = ({ location }: {location: Pick<Location, 'pathname' | 'search'>}) => {
  /**
   * Track page
   *
   * @param {string} pathname - The page to track
   * @param {string} queryString - The query string following the pathname
   */
  const trackPage = (pathname: string, queryString: string) => {
    ReactGA.set({ page: pathname + queryString });
    ReactGA.pageview(pathname + queryString);
  };

  /**
   * useEffect react hooks
   * https://reactjs.org/docs/hooks-effect.html
   *
   * Send the current page to google analytics only when page change.
   */
  useEffect(() => {
    trackPage(location.pathname, location.search);
  }, [location.pathname, location.search]);

  return null;
};

export default GoogleAnalyticsTracker;
