import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import { ErrorMessage, Loader } from '../../components';
import { currentProductIdState } from '../../state';

import { Product } from '../../pages/Product';

interface ProductRoutesParams {
  storeId?: string;
  storeName?: string;
  menuId?: string;
  productId?: string;
}

function ProductRoutes() {
  const { t } = useTranslation();
  const { productId: productIdParam, storeId, storeName } = useParams<ProductRoutesParams>();
  const productId = Number(productIdParam);
  const [currentProductId, setProductId] = useRecoilState(currentProductIdState);

  useEffect(() => {
    if (Number.isInteger(productId) && productId !== currentProductId) {
      setProductId(productId);
    }
  }, [currentProductId, productId, setProductId]);

  if (!currentProductId) {
    return <Loader />;
  }

  return (
    <ErrorBoundary
      fallback={
        <ErrorMessage
          url={`/${storeId}/${storeName}`}
          message={t('digitalmenuapp.error.message.product')}
        />
      }
    >
      <Product />
    </ErrorBoundary>
  );
}

export default ProductRoutes;
