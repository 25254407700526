import React, { PropsWithChildren } from 'react';
import { Modal as BaseModal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { StyledCloseIconContainer, StyledModalContent, StyledModalBody } from './styles';

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const Modal: React.FC<PropsWithChildren<ModalProps>> = ({ isOpen, onClose, children }) => (
  <BaseModal open={isOpen} data-testid="modal" onBackdropClick={onClose}>
    <StyledModalBody>
      <StyledModalContent>{children}</StyledModalContent>
      <StyledCloseIconContainer onClick={onClose} data-testid="modal-close-button">
        <CloseIcon fontSize="large" style={{ color: 'black' }} />
      </StyledCloseIconContainer>
    </StyledModalBody>
  </BaseModal>
);
