import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { StyledContent } from './styles';

export interface CroppedContentProps {
  content: string;
  limit: number;
}

export const CroppedContent: React.FC<CroppedContentProps> = ({ content, limit }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.values['sm']}px)`);

  return (
    <StyledContent>
      {isMobile && content.length > limit ? `${content.substring(0, limit - 3)}...` : content}
    </StyledContent>
  );
};
